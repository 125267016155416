import { notification } from 'antd'
import apiClient from '../index'

export const getHolidayList = async () => {
  try {
    return await apiClient.get('holidays/getAllHolidays')
  } catch (error) {
    if (error) {
      notification.error({
        message: error?.message,
      })
    }
    return null
  }
}

export const addHoliday = async (holidayData) => {
  try {
    return await apiClient.post('holidays', holidayData)
  } catch (error) {
    if (error?.response?.data?.statusCode) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message,
      })
    }
    return null
  }
}

export const updateHoliday = async (data, id) => {
  try {
    return await apiClient.patch(`holidays/${id}`, data)
  } catch (error) {
    if (error?.response?.data?.statusCode) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message,
      })
    }
    return null
  }
}

export const deleteHoliday = async (id) => {
  try {
    return await apiClient.delete(`holidays/${id}`)
  } catch (error) {
    if (error) {
      notification.error({
        message: error?.message,
      })
    }
    return null
  }
}
