import { toggleLoading } from 'redux/settings/actions'
import { getListOfEmployee } from 'services/axios/emp'

export const actions = {
  GET_EMP_LIST_TRAINEE: 'GET_EMP_LIST_TRAINEE',
}

export const getEmployeeListForTrainee = ({ skip, limit, userRole = null, isActive }) => {
  return async (dispatch) => {
    dispatch(toggleLoading(true))
    const response = await getListOfEmployee({ skip, limit, userRole, isActive })
    dispatch({ type: actions.GET_EMP_LIST_TRAINEE, payload: response?.data?.data })
    dispatch(toggleLoading(false))
  }
}
