import { getRosterList, getRosterShiftTiming } from 'services/axios/roster'

export const actions = {
  GET_ROSTER_LIST: 'GET_ROSTER_LIST',
  HANDLE_PAGINATION: 'HANDLE_PAGINATION',
  FETCH_SHIFT_TIMING_SUCCESS: 'FETCH_SHIFT_TIMING_SUCCESS',
  UPDATE_SELECTED_YEAR: 'UPDATE_SELECTED_YEAR',
  UPDATE_SELECTED_MONTH: 'UPDATE_SELECTED_MONTH',
}

// Action creators
export const handlePagination = (pagination) => {
  return {
    type: 'HANDLE_PAGINATION',
    payload: pagination,
  }
}

export const getListOfRoster = ({ skip, limit }) => {
  return async (dispatch) => {
    const response = await getRosterList(skip, limit)
    if (response?.status === 200 && response?.data?.message === 'success') {
      dispatch({
        type: actions.GET_ROSTER_LIST,
        payload: {
          rosterList: response?.data?.data?.rosterList || [],
          totalCount: response?.data?.data?.totalCount,
        },
      })
    }
  }
}

export const fetchShiftTimingSuccess = ({ month, year, empId }) => {
  return async (dispatch) => {
    const response = await getRosterShiftTiming({ month, year, empId })
    if (response?.status === 200 && response?.data?.message === 'success') {
      dispatch({
        type: actions.FETCH_SHIFT_TIMING_SUCCESS,
        payload: {
          getRosterShiftTiming: response?.data?.data,
        },
      })
    }
  }
}

export const updateSelectedYear = (year) => {
  return {
    type: actions.UPDATE_SELECTED_YEAR,
    payload: year,
  }
}

export const updateSelectedMonth = (month) => {
  return {
    type: actions.UPDATE_SELECTED_MONTH,
    payload: month,
  }
}
