import { actions } from 'redux/Trainee/action'

const initialState = {
  traineeListForEmployee: {},
}

const traineeList = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_EMP_LIST_TRAINEE:
      return {
        ...state,
        traineeListForEmployee: action.payload,
      }
    default:
      return state
  }
}
export default traineeList
