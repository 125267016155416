import { actions } from './action'

const initialState = {
  rosterList: [],
  totalCount: 0,
  shiftTimingData: [],
  selectedYear: null,
  selectedMonth: null,
  pagination: {
    skip: 0,
    limit: 10,
  },
}

const roster = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ROSTER_LIST:
      return {
        ...state,
        rosterList: action.payload.rosterList,
        totalCount: action.payload.totalCount,
      }
    case actions.HANDLE_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      }
    case actions.FETCH_SHIFT_TIMING_SUCCESS:
      return {
        ...state,
        shiftTimingData: action.payload.getRosterShiftTiming,
      }
    case actions.UPDATE_SELECTED_YEAR:
      return {
        ...state,
        selectedYear: action.payload,
      }
    case actions.UPDATE_SELECTED_MONTH:
      return {
        ...state,
        selectedMonth: action.payload,
      }
    default:
      return state
  }
}
export default roster
