import { notification } from 'antd'
import apiClient from '../index'

export const uploadRoster = (data) => apiClient.post('roster', data)
export const getRosterList = (skip, limit) =>
  apiClient.get('roster', {
    params: {
      skip,
      limit,
    },
  })

export const saveRosterShift = async (rosterShiftData) => {
  try {
    return await apiClient.post(`roster/saveRosterShift`, rosterShiftData)
  } catch (error) {
    if (error?.response?.data?.statusCode) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message,
      })
    }
  }
  return null
}

export const getRosterShiftTiming = async ({ empId, year, month }) => {
  try {
    return await apiClient.get('roster/getRosterShiftTiming', {
      params: {
        month,
        year,
        empId,
      },
    })
  } catch (error) {
    if (error?.response?.data?.statusCode) {
      return {
        message: 'Error',
        description: error.response.data.message,
      }
    }
    return null
  }
}
