import { actions } from './action'

const initialState = {
  holidayList: [],
  totalCount: 0,
}

const holidayListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_HOLIDAY_LIST:
      return {
        ...state,
        holidayList: action.payload.holidayList,
        totalCount: action.payload.totalCount,
      }
    default:
      return state
  }
}
export default holidayListReducer
