import { getHolidayList } from 'services/axios/holidays'

export const actions = {
  GET_HOLIDAY_LIST: 'GET_HOLIDAY_LIST',
}
export const getHolidayListAction = ({ skip, limit }) => {
  return async (dispatch) => {
    try {
      const response = await getHolidayList({ skip, limit })
      dispatch({
        type: actions.GET_HOLIDAY_LIST,
        payload: {
          holidayList: response?.data?.data?.holidayList,
          totalCount: response?.data?.data?.totalCount,
        },
      })
    } catch (error) {
      console.error('Error fetching holiday list:', error)
    }
  }
}
